import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import { CenteredContent, StyledLink } from "../components/utils"
import Seo from "../components/seo"

const S = {}
S.Paragraph = styled.p`
  font-size: 22px;
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404" />
    <CenteredContent>
      <S.Paragraph>Nic tutaj nie ma...</S.Paragraph>
      <p>
        <StyledLink to="/">Wróć do strony głównej</StyledLink>
      </p>
    </CenteredContent>
  </Layout>
)

export default NotFoundPage
